<template>
  <div class="policy-guide-content">
    <div class="particular">
      <div id="jbxx" class="pgc-item">
        <h2 class="pgc-title">套餐事项</h2>
        <el-divider class="divider"></el-divider>
        <div class="particular-content">
          <el-table :data="matterData" stripe border size="medium">
            <el-table-column
              prop="servicename"
              label="事项名称"
              header-align="center"
            ></el-table-column>
            <el-table-column label="办事指南" header-align="center" width="120">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  class="tableBtn"
                  @click="toserviceGuide(scope.$index, scope.row)"
                  >办事指南</el-button
                >
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="resultType"
              label="在线办理"
              header-align="center"
            ></el-table-column> -->
          </el-table>
        </div>
      </div>
      <div id="jbxx" class="pgc-item">
        <h2 class="pgc-title">基本信息</h2>
        <div class="desContent" ref="desContent">
          <descTable :items="cpu_baseList" labelWidth="160px"></descTable>
        </div>
        <button
          v-text="buttonType == '1' ? '查看全部' : '收起'"
          class="typeBtn"
          @click="showMore"
        ></button>
        <div id="sqcl" class="pgc-item">
          <h2 class="pgc-title">办理流程</h2>
          <el-divider class="divider"></el-divider>
          <div class="particular-content particular-sblc">
            <!-- <div style="min-height: 600px">
              <el-steps direction="vertical" :active="stepList.length">
                <el-step v-for="(item, index) in stepList" :key="index">
                  <template slot="title">
                    <el-row>
                      <el-col :span="12" align="left">{{ item.title }}</el-col>
                      <el-col :span="12" align="right" style="color: #a5a4a4">
                        <span>时限：{{ item.time }}</span>
                        <span>审批人：{{ item.person }}</span>
                      </el-col>
                    </el-row>
                  </template>
                  <template slot="description">
                    <el-row class="desTitle">
                      <span style="font-weight: 600; color: #333"
                        >办理结果：</span
                      >
                      <span style="color: #333">{{ item.result }}</span>
                    </el-row>
                    <el-row class="desCont">
                      <h4>审查标准</h4>
                      <p>{{ item.content }}</p>
                    </el-row>
                  </template>
                </el-step>
              </el-steps> 
            </div> -->
          </div>
        </div>
        <div id="spjg" class="pgc-item">
          <h2 class="pgc-title">申报材料</h2>
          <el-divider class="divider"></el-divider>
          <div class="particular-content">
            <el-table :data="showMaterialList" stripe border size="medium">
              <el-table-column
                prop="name"
                label="材料名称"
                show-overflow-tooltip
                header-align="center"
              ></el-table-column>
              <el-table-column
                prop="necessityDesc"
                label="材料依据"
                width="400px"
                show-overflow-tooltip
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="srcWay"
                label="材料来源"
                header-align="center"
              ></el-table-column>
              <el-table-column
                prop="memo"
                label="材料要求"
                show-overflow-tooltip
                header-align="center"
              ></el-table-column>
              <el-table-column
                prop="format"
                label="材料类型"
                header-align="center"
              ></el-table-column>
              <el-table-column
                prop="submitMedium"
                label="份数"
                header-align="center"
              ></el-table-column>
              <el-table-column
                prop="importLevel"
                label="是否可容缺"
                header-align="center"
              ></el-table-column>
              <el-table-column
                prop="acceptRequire"
                label="存档要求"
                show-overflow-tooltip
                header-align="center"
              ></el-table-column>
            </el-table>
            <el-pagination
              class="mt-10"
              background
              hide-on-single-page
              :total="total"
              :page-size="pagesize"
              @current-change="changePageNum"
              prev-text="上一页"
              next-text="下一页"
              layout="prev, pager, next,total, jumper"
              style="margin: 20px auto"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import descTable from "@/components/descTable";

export default {
  name: "PolicyGuideContent",
  components: {
    descTable,
  },
  props: {
    matterUnid: {
      type: String,
    },
    matterObj: {
      type: Object,
    },
    matterDetailsObj: {
      type: Object,
    },
  },
  data() {
    return {
      tableData: [],
      buttonType: "1",
      value1: null,
      stepList: [],
      matterData: [], //事项数据
      materialList: [], //材料对比数据
      showMaterialList: [], //材料展示数据
      pagesize: 5,
      total: null,
      pageNum: 1,
    };
  },

  computed: {
    /**
     * @description: 基本信息列表
     * @param {*}
     * @return {*}
     */
    cpu_baseList() {
      let list = [
        {
          title: "'一件事'套餐名称",
          content: this.matterObj.scenename,
          cellType: "1",
        },
        {
          title: "主题分类",
          content: this.matterObj.themetype,
          cellType: "1",
        },
        {
          title: "服务对象",
          content: this.matterObj.servicepeople,
          cellType: "1",
        },
        {
          title: "事项类型",
          content: this.matterObj.themetype,
          cellType: "1",
        },
        {
          title: "主办单位",
          content: this.matterObj.zhubanunit,
          cellType: "1",
        },
        {
          title: "牵头单位",
          content: this.matterObj.qiantouunit,
          cellType: "1",
        },
        {
          title: "办件类型",
          content: this.matterObj.banjiantype,
          cellType: "1",
        },
        {
          title: "跑动次数",
          content: this.matterObj.paodongnum,
          cellType: "1",
        },
        {
          title: "办理条件",
          content: this.matterObj.banlitiaojian,
          cellType: "1",
        },
        {
          title: "前置条件",
          content: this.matterObj.qianzhitiaojian,
          cellType: "1",
        },
        {
          title: "法定办理时间",
          content: this.matterObj.fadingtime,
          cellType: "1",
        },
        {
          title: "承诺办理时间",
          content: this.matterObj.promisetime,
          cellType: "1",
        },
        {
          title: "联办事项名称",
          content: "",
          cellType: "2",
        },
        {
          title: "联办单位",
          content: this.matterObj.serviceunit,
          cellType: "2",
        },
        {
          title: "实施依据",
          content: this.matterObj.shishiyiju,
          cellType: "2",
        },
        {
          title: "办理流程",
          content: this.matterObj.handlprocess,
          cellType: "2",
        },
        {
          title: "中介名称",
          content: this.matterObj.zhongjiename,
          cellType: "1",
        },
        {
          title: "实施依据",
          content: this.matterObj.shishiyiju,
          cellType: "1",
        },
        {
          title: "证明名称",
          content: this.matterObj.zhengmingname,
          cellType: "1",
        },
        {
          title: "实施依据",
          content: this.matterObj.shishiyiju,
          cellType: "1",
        },
        {
          title: "是否收费",
          content: this.matterObj.isshoufei,
          cellType: "1",
        },
        {
          title: "收费依据",
          content: this.matterObj.shoufeiyiju,
          cellType: "1",
        },
        {
          title: "投诉方式",
          content: this.matterObj.tousufanghi,
          cellType: "2",
        },
        {
          title: "咨询方式",
          content: this.matterObj.zixunfangshi,
          cellType: "2",
        },
        {
          title: "备注",
          content: this.matterObj.remark,
          cellType: "2",
        },
      ];
      return list;
    },
  },
  created() {
    // //办理流程
    // let handleProcess = this.matterObj.handlprocess;
    // handleProcess = handleProcess.split("\n");
    // handleProcess.forEach((item) => {
    //   let amongList = item.split("；");
    //   let obj = {
    //     title: amongList[0].substring(5),
    //     time: amongList[2].substring(5),
    //     person: amongList[1].substring(4),
    //     result: amongList[4].substring(5),
    //     content: amongList[3].substring(5),
    //   };
    //   this.stepList.push(obj);
    // });
    let matterobj = this.matterObj;
    let servicename = matterobj.servicename.split(",");
    let serviceunid = matterobj.serviceunid.split(",");
    let servicecode = matterobj.servicecode.split(",");
    servicename.forEach((element, index) => {
      let obj = {
        servicename: servicename[index],
        serviceunid: serviceunid[index],
        servicecode: servicecode[index],
      };
      this.matterData.push(obj);
    });
    this.matterData.pop();
    this.matterData.forEach((item) => {
      this.getMatterDetailsList(item.serviceunid);
    });
  },
  watch: {},
  methods: {
    showMore() {
      let desContent = this.$refs.desContent;
      this.buttonType = this.buttonType == "1" ? "2" : "1";
      desContent.style.height =
        desContent.clientHeight == 240 ? "680px" : "240px";
    },
    toserviceGuide(index, row) {
      sessionStorage.setItem("matterunid", JSON.stringify(row.serviceunid));
      sessionStorage.setItem("matterChooseType", "2");
      this.$router.push({ name: "serviceGuide",params:{linkType:true} });
    },
    getMatterDetailsList(unid) {
      let url = "/preService/service/getPreServiceDetailedInfo/" + unid;
      let that = this;
      this.$httpApi
        .get(url)
        .then((r) => {
          let list = r.data.preServiceMaterialList;
          list.forEach((item) => {
            that.materialList.push(item);
          });
        })
        .then((res) => {
          that.total = that.materialList.length;
          that.showMaterialList = that.materialList.slice(0, 5);
        });
    },
    // 分页切换
    changePageNum(index) {
      this.pageNum = index;
      let pageSize = this.pagesize;
      let pageNum = this.pageNum;
      this.showMaterialList=this.materialList.slice((pageNum-1)*pageSize,(pageNum-1)*pageSize+5)
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-guide-content {
  position: relative;
}
.pgc-item {
  margin-top: 40px;
}
.pgc-title {
  margin-bottom: 10px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei UI Light", "Microsoft YaHei UI", "Microsoft YaHei",
    "微软雅黑", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
}
.easyItem-content {
  line-height: 2;
  text-indent: 28px;
}
.particular-content {
  line-height: 2;
  white-space: pre-wrap;
}
.text-i-28 {
  text-indent: 28px;
}
::v-deep .easy-zcnr-table {
  .table-sbdx-header {
    th {
      font-weight: normal;
      color: #ffffff;
      background-color: $col-theme;
    }
  }
  .table-sbdx-row {
    .table-sbdx-cell-gssz {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      p {
        flex: 1;
        padding-right: 20px;
      }
      .el-button {
        padding: 5px;
      }
    }
  }
}
.particular-sblc {
  ::v-deep img {
    width: 100%;
    max-width: 700px;
  }
}
.particular {
  ::v-deep .el-table {
    .el-table__header-wrapper {
      th {
        font-weight: normal;
        color: #000000;
        background-color: #f2f2f2;
      }
    }
  }
}
.divider {
  height: 4px;
  background-color: #333;
}
::v-deep .el-divider--horizontal {
  margin: 10px 0 24px 0;
}
.typeBtn {
  width: 100%;
  background-color: #f1f2f3;
  text-align: center;
  font-size: 14px;
  height: 40px;
  margin-top: 15px;
  color: #2850a0 !important;
  cursor: pointer;
}
.desContent {
  overflow: hidden;
  height: 240px;
}
.tableBtn {
  border: 1px solid #bd1a2d;
  color: #bd1a2d;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #e0240a;
}
</style>
