<template>
  <div class="twoColumnTable">
    <div class="box">
      <div class="cell-container" v-if="items.length > 0">
          <div :class="item.cellType=='1'?'cell':'cell2'" v-for="(item, i) in items" :key="i">
          <div class="pad-1 title max-one-line" :style="{ width: labelWidth }">
            {{ item.title || item.TITLE }}
          </div>
          <!--<div class="content max-one-line">{{ item.content || item.CONTENT }}</div>-->
          <!--使用tooltip,鼠标悬浮到长文本上可以显示-->
          <div class="pad-1 content">
            <!-- <el-tooltip
              offset="-2"
              class="item"
              effect="dark"
              placement="top"
              :content="item.content || item.CONTENT"
            > -->
            <p class="content-item" :title="item.content || item.CONTENT">
              {{ item.content || item.CONTENT }}
            </p>
            <!-- </el-tooltip> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "twoColumnTable",
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
// 最多一行
.max-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.twoColumnTable {
  width: 100%;
  margin: 10px 0;
}

.cell-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid rgb(226, 226, 226);
  border-left: 1px solid rgb(226, 226, 226);
}

.cell,.cell2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  color: #000;

  .title {
    color: #333333;
    background: #efefef;
    border-right: 1px solid rgb(226, 226, 226);
    border-bottom: 1px solid rgb(226, 226, 226);
    box-sizing: border-box;
  }

  .content {
    flex: 1;
    flex-shrink: 0;
    background: #ffffff;
    text-align: left;
    border-right: 1px solid rgb(226, 226, 226);
    border-bottom: 1px solid rgb(226, 226, 226);
    box-sizing: border-box;
    overflow: hidden;

    .content-item {
      // width: 100%;
      white-space: nowrap;
      -ms-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.cell {
  width: 50%;
}
.cell2 {
  width: 100%;
}
  
</style>
