<template>
  <div class="policy-guide">
    <div class="def-container wrap-header">
      <div>
      <img src="~@/assets/images/positionImg.png" alt="" style="">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="font-size:18px;display:inline-block;margin-left:8px">
          <el-breadcrumb-item :to="{ path: '/'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: 'enterpriseLibrary'}" >企业办事库</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: 'onethingGuide'}">场景指南</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <h1 class="policy-name">{{ matterObj.scenename }}</h1>
      <div class="header_bottom"></div>
    </div>
    <div class="def-container wrap-content">
      <div class="content_left">
        <oneThingGuideContent
          class="marg-tb-3"
          :matterObj="matterObj"
        ></oneThingGuideContent>
      </div>
    </div>
  </div>
</template>

<script>
import oneThingGuideContent from "./oneThingGuideContent";

const NOW = new Date();

export default {
  name: "guide",
  components: {
    oneThingGuideContent,
  },
  data() {
    return {
      offsetTop: 28,
      collectItemId: "",
      guideId: "",
      starNum: 5,
      matterObj: {}, //场景信息
    };
  },
  computed: {},

  created() {
    this.matterObj = JSON.parse(sessionStorage.getItem("onethingGuideItem"));
    // this.matterObj=sessionStorage.getItem('onethingGuideItem')
  },
  mounted() {},
  methods: {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.policy-guide {
  padding-top: 20px;
}
.wrap-header {
  padding-bottom: 10px;
  border-bottom: 1px solid $col-separator;
  margin-bottom: 20px;

  .policy-name {
    margin-bottom: 10px;
    text-align: center;
    font-weight: normal;
    color: #000000;
  }
  .header_bottom {
    display: -webkit-flex;
    display: flex;
    align-items: flex-end;
  }
  .policy-infos {
    flex: 1;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .policy-info-item {
    display: -webkit-flex;
    display: flex;
    width: 350px;

    .info-label {
      margin-right: 5px;
    }
    .info-value {
      flex: 1;
    }
  }
  .guide-btns {
    ::v-deep .el-button {
      padding-top: 0;
      padding-bottom: 0;
      height: 40px;
      vertical-align: middle;
    }
    .btn-collect {
      width: 100px;

      .collect_inner {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .collect-icon,
      .collect-text {
        vertical-align: middle;
      }

      .collect-icon {
        font-size: 18px;

        &.el-icon-star-on {
          color: $col-yellow;
        }
      }
    }
  }
}
.wrap-content {
  display: -moz-grid;
  display: -ms-grid;
  display: grid;
  grid-template-columns: calc(100% - 100px - 20px) auto;

  .content_left {
    -ms-grid-column: 1;
  }
}
.guide-version {
  display: -webkit-flex;
  display: flex;
  align-items: center;

  h2 {
    margin-right: 20px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei UI Light", "Microsoft YaHei UI", "Microsoft YaHei",
      "微软雅黑", Arial, sans-serif;
    color: #000000;
  }
}
::v-deep .guide-card {
  margin-bottom: 20px;

  .el-card__header {
    padding: 10px;
    text-align: center;

    i {
      margin-right: 5px;
    }
  }
  .el-card__body {
    padding: 10px;
  }
}
::v-deep .card-text {
  position: relative;

  .card-text-content {
    position: relative;

    a {
      text-decoration: underline $col-theme;
      color: $col-theme;
    }
    .content-type {
      display: inline-block;
      padding-right: 5px;
      padding-left: 5px;
      margin-top: 10px;
      color: #ffffff;
      background-color: $col-theme;
    }
  }

  .card-text-footer {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    color: #999999;

    .card-text-jg {
      flex: 1;
      padding-right: 10px;
    }
  }
}
::v-deep .card-question {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  z-index: 99;

  .question-content-item + .question-content-item {
    margin-top: 20px;
  }
  .contentItem-section {
    display: -webkit-flex;
    display: flex;

    .section-label {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      background-color: $col-theme;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        border-right: 5px solid transparent;
        border-bottom: 2px solid $col-theme;
        border-left: 5px solid transparent;
      }

      &.section-label-a {
        background-color: $col-green;

        &::before {
          border-bottom: 2px solid $col-green;
        }
      }
    }
    .section-value {
      flex: 1;
    }
    .section-value-q {
      color: #727475;
    }
    .section-value-a {
      color: #727475;
    }
  }
  .contentItem-section + .contentItem-section {
    margin-top: 5px;
  }
}

.policy-info {
  flex: 1;
  display: -moz-grid;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  gap: 10px 10px;
  color: #ffffff;

  .policy-info-item {
    display: -webkit-flex;
    display: flex;

    .infoItem-label {
      margin-right: 5px;
    }
    .infoItem-value {
      flex: 1;
    }
  }
}
.borderDashed {
  border-bottom: 1px dashed #ccc;
  min-height: 80px;
  padding: 10px 8px;
}
</style>
